<template>
  <Alert :isActive="isError" v-if="isError">{{errorMessage}}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
  <Navigation></Navigation>
  <div class="theme-container" :isShow="!isLoading">
    <PageTitle
      :title="content['page-title']"
      :imgSrc="images['header3.svg']"
    ></PageTitle>
  </div>
  <div class="section-container">
    <router-link to="/special-events" class = "nav-back">
        <img style="margin-right: 20px" src='@/assets/images/previous-icon.svg' />
        BACK TO ALL EVENTS
    </router-link>
    
    <div class="container">
        <div class="item">
            <h3 class = "subtitle">{{ events[clickedEventKey ? clickedEventKey : 0].subtitle }}</h3>
            <h1>{{ events[clickedEventKey ? clickedEventKey : 0].title}}</h1>
            <p style="font-size: 26px"> {{events[clickedEventKey ? clickedEventKey : 0].titleDetails}} </p>
            <h3 class = "date">{{ events[clickedEventKey ? clickedEventKey : 0].date }}</h3>
        </div>
        <div class="item">
            <img :src="events[clickedEventKey ? clickedEventKey : 0].imgSrc" alt="Slalom White Logo" class = "title-image"/>
        </div>
        <div class="break"> </div>
        <div class="item-full">
            <p> {{events[clickedEventKey ? clickedEventKey : 0].description}} </p>
        </div>
        <div class="item-full" v-for="notes in events[clickedEventKey ? clickedEventKey : 0].notes">
            <p> {{notes.description}} </p>
            <p style = "font-weight: 600"> {{notes.title}} </p>
            <ul v-for="text in notes.text">
              <li>{{text}} </li>
            </ul>
        </div>
        <div class="item-full-center" v-if="events[clickedEventKey ? clickedEventKey : 0].optionalImgSrc">
            <img :src="events[clickedEventKey ? clickedEventKey : 0].optionalImgSrc" alt="Slalom White Logo" class = "optional-image"/>
        </div>
    </div>

    <div class= "carousel-container">
        <router-link :to="`/special-events/all-events/${parseInt(clickedEventKey) > 0 ? clickedEventKey - 1 : eventCount - 1}`" class="controller-link">
        <img src='@/assets/images/previous-icon.svg' class="carousel-arrow"/>
            {{parseInt(clickedEventKey) + 1}}
        </router-link>
        of
        <router-link :to="`/special-events/all-events/${(parseInt(clickedEventKey) + 1) % eventCount}`" class="controller-link">
            {{eventCount}}
            <img src='@/assets/images/previous-icon.svg' style="transform: scaleX(-1);" class="carousel-arrow" />
        </router-link>
    </div>

  </div>
  </Loading>
</template>

<script>

import Navigation from "../components/Navigation.vue";
import PageTitle from "../components/PageTitle.vue";
import api from "@/services/api.js";
import Loading from '@/components/Loading.vue';
import Alert from '@/components/Alert.vue';

export default {
    props: ["clickedEventKey"],
    data: () => ({
      content: {},
      images: {},
      isLoading: false,
      isError: false,
      errorMessage: '',
      events: [],
      eventCount: 4,
    }),

	methods: {
		async getData() {
			this.isError = false;
			this.isLoading = true;
			try {
				const { data } = await api.getContent('special-events');
				this.content = data.content;
				this.images = data.images;
        this.events = [
          {
          subtitle: this.content['event-1-title-1'],
          title: this.content['event-1-title-2'],
          titleDetails: this.content['event-4-title-3'],
          date: this.content['event-1-date'],
          description: this.content['event-4-description-long-1'],
          imgSrc: `${this.images[`event-1-photo3.png`]}`,
          notes: [
            {title: this.content['event-4-list-1-title'], text: [this.content['event-4-list-1-item-1'], this.content['event-4-list-1-item-2'], this.content['event-4-list-1-item-3']]},
            {title: this.content['event-4-list-2-title'], description: this.content['event-4-description-long-2'] , text: [this.content['event-4-list-2-item-1'], this.content['event-4-list-2-item-2'], this.content['event-4-list-2-item-3'], this.content['event-4-list-2-item-4']], }
          ]
        }, {
          subtitle: this.content['event-2-title-1'],
          title: this.content['event-2-title-2'],
          titleDetails: this.content['event-5-title-3'],
          date: this.content['event-2-date'],
          description: this.content['event-2-description-long'],
          imgSrc: `${this.images[`event-1-photo.png`]}`,
          optionalImgSrc: `${this.images[`event-1-subsection-photo.png`]}`
        
        }, {
          subtitle: this.content['event-3-title-1'],
          title: this.content['event-3-title-2'],
          titleDetails: this.content['event-6-title-3'],
          date: this.content['event-3-date'],
          description: this.content['event-6-description-long'],
          imgSrc: `${this.images[`event-3-photo3.png`]}`,
           notes: [
            {title: this.content['event-6-list-1-title'], text: [this.content['event-6-list-1-item-1'], this.content['event-6-list-1-item-2'], this.content['event-6-list-1-item-3']]},
            {title: this.content['event-6-list-2-title'], text: [this.content['event-6-list-2-item-1'], this.content['event-6-list-2-item-2'], this.content['event-6-list-2-item-3']]},
          ]
        },  {
          subtitle: this.content['event-4-title-1'],
          title: this.content['event-4-title-2'],
          titleDetails: this.content['event-7-title-3'],
          date: this.content['event-4-date'],
          description: this.content['event-7-description-long'],
          imgSrc: `${this.images[`event-3-photo.png`]}`,
          optionalImgSrc: `${this.images[`event-3-subsection-photo.png`]}`,
          notes: [
            {title: this.content['event-7-list-1-title'], text: [this.content['event-7-list-1-item-1'], this.content['event-7-list-1-item-2'], this.content['event-7-list-1-item-3']]},
          ]
        }, 
         {
         subtitle: this.content['event-5-title-1'],
        }, 
        {
         subtitle: this.content['event-6-title-1'],
        }, 
        {
         subtitle: this.content['event-7-title-1'],
        }, 
        ]
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		}
	},

	async beforeRouteUpdate(to, from, next) {
		await this.getData();
		next();
	},

	async created() {
		await this.getData();
	},

  components: {
    PageTitle,
    Navigation,
    Loading,
    Alert,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";


.theme-container {
  @include relative;
  :deep(.page-title) {
    .title-container{
      @include bg-color(takeda-dark-red);
    }
  }
}

.nav-back {
  @include color(takeda-dark-red);
  text-decoration: none;
  display: flex;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 50px;
  @include responsive(phone) {
    margin-left: 25px;
  }
}

.title-image {
    max-width:100%;
    margin-top: -25%;
    margin-left: 50px;
    @include responsive(phone) {
        @include margin(0);
        min-width: 0;
        min-height: 0;
        max-width: 100%;
    }
    @include responsive(tablet) {
        @include margin(0);
        min-width: 0;
        min-height: 0;
        max-width: 100%;
    }
}

h1 {
  @include color(takeda-dark-red)
}

.break {
  flex-basis: 100%;
  height: 0;
}

.section-container {
  @include bg-color(takeda-light-grey);
  @include padding(10 10 10 10);
  @include margin(0 0 8 0);
  @include responsive(phone) {
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  z-index: 1;
}

.container {
  @include bg-color(takeda-white);
  @include padding(8);
  border-bottom-right-radius: 4em;
  display: flex;
  flex-wrap: wrap;
  @include responsive(phone) {
    @include padding(4 2 4 2);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.item {
  flex-basis: 50%;
  align-items: flex-start;
    @include responsive(phone) {
        flex-basis: 100%;
    }
    @include responsive(tablet) {
        flex-basis: 100%;
    }
}

.item-full {
  flex-basis: 100%;
  align-items: flex-start;
}

.item-full-center {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optional-image {
   margin-top: 50px;
   max-width:100%;
}

.arrow {
  margin-left: 10px;
}

.date {
  @include color(takeda-medium-gray);
  font-size: 18px;
  margin-bottom: 10px;

}

.subtitle {
  @include color(takeda-dark-red);
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;

}

.carousel-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 5px;
  font-weight: 600;
  margin-top: 5%;
  @include color(slalom-dark-gray)
}

.carousel-arrow {
  @include padding(0 2 0 2);
}

.controller-link {
  @include color(takeda-dark-grey);
  display: flex;
  text-align: center;
  text-decoration: none; 
  color: inherit;
}

</style>
